import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { getPath, getJsonResponse } from '../../api';
import {
  Loader,
  PageTitle,
  EventDetails,
} from "@components"

const EventPage = ({ location }) => {
  const { pathname } = location;
  const eventID = pathname.split('/').pop();

  const [event, setEvent] = useState(null);
  const [eventLoading, setEventLoading] = useState(true);

  useEffect(() => {
    const endpointPath = getPath(`/api/v1/offering-events/${eventID}`);
    const config = {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer 8a1bbc2b-6970-458f-8363-ff6e53c5b53c',
        'Content-Type': 'application/json'
      },
    };
    fetch(endpointPath, config)
      .then(getJsonResponse)
      .then((data) => {
        setEvent(data);
        setEventLoading(false);
      })
      .catch(() => {
        setEventLoading(false);
      });
  }, [eventID]);

  if (eventLoading) {
    return (
      <Layout>
        <Loader/>
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo title={event?.event?.name} />
      <PageTitle title={event?.event?.name} />
      <EventDetails event={event} />
    </Layout>
  )
}

export default EventPage
